import { Instagram, Facebook, Linkedin } from "lucide-react";
import type { GetServerSidePropsContext } from "next";
import localFont from "next/font/local";

import { getServerSession } from "@calcom/features/auth/lib/getServerSession";
import useMediaQuery from "@calcom/lib/hooks/useMediaQuery";
import { HeadSeo, Logo } from "@calcom/ui";

const gtAmericaFont = localFont({ src: "../fonts/GT-America-Standard-Regular.woff2" });
const recifeTextFont = localFont({ src: "../fonts/RecifeText-SemiBold.otf" });

function HomePage() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const renderHeader = () => (
    <div className="header-container">
      {renderLogo()}
      {renderLoginButton()}
    </div>
  );

  const renderLogo = () => (
    <div className="logo-container">
      <Logo icon={isMobile} size={12} inline={false} className="mx-auto mb-auto" />
    </div>
  );

  const renderLoginButton = () => (
    <div className="login-button-container">
      <a href="/auth/login" className={`login-button${isMobile ? " mobile" : ""}`}>
        Login
      </a>
    </div>
  );

  const renderContent = () => (
    <div className="content-container">
      <h3 className={recifeTextFont.className}>Hopkins Calendar</h3>
      <p>
        Hopkins Calendar, powered by the Cal.com platform, is a specialized scheduling tool crafted to enhance
        lawyers' calendar management. It streamlines the process of booking appointments between attorneys and
        clients, ensuring optimal efficiency. It's part of Hopkins' suite of integrated software products.
      </p>
      <h3 className={recifeTextFont.className}>User Data Handling and Usage</h3>
      <p>
        Please view our privacy policy{" "}
        <a href="https://www.hopkins.systems/datenschutz" target="_blank">
          here
        </a>{" "}
        and Cal.com's privacy policy{" "}
        <a href="https://cal.com/privacy" target="_blank">
          here
        </a>
        .
      </p>
      <h3 className={recifeTextFont.className}>Google OAuth2 Limited Use Disclosure</h3>
      <p>
        Hopkins Calendar's use and transfer to any other app of information received from Google APIs will
        adhere to{" "}
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          target="_blank">
          Google API Services User Data Policy
        </a>
        , including the Limited Use requirements.
      </p>
      <br />
      <p>Hopkins Calendar's Integration with Google Data:</p>
      <ul>
        <li>
          Read Access to Your Calendar: This permission facilitates the identification of available timeslots,
          ensuring seamless scheduling by preventing overlaps with existing events in your Google calendar.
        </li>
        <li>
          Write Access to Your Calendar: Granting this access enables Hopkins Calendar to secure and allocate
          specific timeslots, thereby allowing the creation of events directly within your Google calendar.
        </li>
      </ul>
    </div>
  );

  const renderFooter = () => (
    <div className="footer-container">
      <div className="footer-inner-container">
        <Logo size={9} inline={false} className="mb-4" />
        <div className="footer-inner-left-container">
          <p>Hopkins GmbH</p>
          <p>Weichselstr. 14, c/o Wolff, 12045 Berlin</p>
          <p>Vertreten durch: Simon Wolff</p>
          <p>District Court of Charlottenburg (Berlin), HRB 208931 B</p>
          <p>VAT-ID: DE325717360</p>
        </div>
        <div className="footer-inner-right-container">
          <p>contact@hopkins.law</p>
          <p>030 6293 9155</p>
          <p>Mo. - Fr. 9:00 - 19:00 Uhr</p>
          <p>© 2023 Hopkins Rechtsanwälte</p>
          <div className="footer-inner-right-container-social-media">
            <a href="https://www.instagram.com/kanzleihopkins" target="_blank">
              <Instagram className="mr-2" />
            </a>
            <a href="https://www.facebook.com/kanzleihopkins" target="_blank">
              <Facebook className="mr-2" />
            </a>
            <a href="https://www.linkedin.com/company/67266925" target="_blank">
              <Linkedin className="mr-2" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`main-page-container ${gtAmericaFont.className}`}>
      <HeadSeo title="Home" description="Hopkins Calendar" />
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
    </div>
  );
}

export async function getServerSideProps({ req, res }: GetServerSidePropsContext) {
  const session = await getServerSession({ req, res });

  if (session?.user?.id) {
    return { redirect: { permanent: false, destination: "/event-types" } };
  }

  return {
    props: {},
  };
}

export default HomePage;