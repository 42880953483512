import classNames from "@calcom/lib/classNames";

export default function Logo({
  small,
  size,
  black = false,
  icon,
  inline = true,
  className,
  src = "/api/logo",
}: {
  small?: boolean;
  size?: number;
  black?: boolean;
  icon?: boolean;
  inline?: boolean;
  className?: string;
  src?: string;
}) {
  const sizeClassName = size ? `h-${size} w-auto` : small ? "h-4 w-auto" : "h-5 w-auto";
  const type = black ? (icon ? "icon-black" : "logo-black") : icon ? "icon" : "logo";
  const source = src || `/api/logo?type=${type}`;

  return (
    <h3 className={classNames("logo", inline && "inline", className)}>
      <strong>
        {icon ? (
          <img className="mx-auto w-9 dark:invert" alt="Hopkins" title="Hopkins" src={source} />
        ) : (
          <img className={classNames(sizeClassName, "dark:invert")} alt="Hopkins" title="Hopkins" src={source} />
        )}
      </strong>
    </h3>
  );
}
